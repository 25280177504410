.App {
  background-image: linear-gradient(
    83.2deg,
    rgba(150, 93, 233, 1) 10.8%,
    rgba(99, 88, 238, 1) 94.3%
  );
}

.Form{
  box-shadow: 0px 0px 20px black;
}

.form-body{
  max-height: 65vh;
}

.form-error-msg{
  color: red;
  font-size: small;
}

/* Home page img */
.img-container{
  position: relative;
}
.img-container .backdrop{
  position: absolute;
  content: '';
  height: 350px;
  width: 30vw;
  border-radius: 51% 49% 71% 29% / 39% 47% 53% 61%  ;
  left: 0%;
  bottom: -20%;
  background: whitesmoke ;
  opacity: 0.5;
  animation: move 8s ease-in-out infinite;
}
.img-container img{
  position: relative;
  z-index: 1;
  left: -12%;
  bottom: -20%;
}

@keyframes move {
  50%{
    transform: translate(5%,5%);
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70% ;
  }
}

/* Media query */
@media only screen and (max-width: 700px) {
  .img-container{
    display: none;
  }
  .App > *{
    flex: 1;
  }
  .Form{
    margin: 30px 50px;
  }
  .generate-page > div{
    flex-direction: column;
  }
  .generate-page > div > *{
    width: 90vw;

  }
}